<template>
  <div class="trend-word">
    <div class="search-filter-form">
      <div class="filter-area d-flex">
        <div class="button">
          <b-button variant="outline-primary">선택</b-button>
          <b-button variant="outline-secondary">미선택</b-button>
          <b-button variant="outline-secondary">미선택2</b-button>
        </div>
        <div class="radio">
          <b-form-radio-group v-model="radio">
            <b-form-radio value="라디오">라디오</b-form-radio>
            <b-form-radio value="라디오1">라디오1</b-form-radio>
            <b-form-radio value="라디오2">라디오2</b-form-radio>
            <b-form-radio value="라디오3">라디오3</b-form-radio>
          </b-form-radio-group>
        </div>
        <!-- 여백위해 넣은 div / 모바일에서는 없어도 OK start -->
        <div class="flex-grow-1"></div>
        <!-- 여백위해 넣은 div / 모바일에서는 없어도 OK end -->
        <div class="date">
          <button class="inner_btn" variant="primary" size="sm"><i class="bi bi-chevron left"></i></button>
          <b-form-datepicker v-model="date" class="inner_picker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
          <button class="inner_btn" variant="primary" size="sm"><i class="bi bi-chevron right"></i></button>
        </div>
        <div class="select">
          <button class="inner_btn" variant="primary" size="sm"><i class="bi bi-chevron left"></i></button>
          <b-form-select v-model="select" class="inner_select">
            <b-form-select-option value="셀렉트">셀렉트</b-form-select-option>
            <b-form-select-option value="셀렉트1">셀렉트1</b-form-select-option>
            <b-form-select-option value="셀렉트2">셀렉트2</b-form-select-option>
            <b-form-select-option value="셀렉트3">셀렉트3</b-form-select-option>
          </b-form-select>
          <button class="inner_btn" variant="primary" size="sm"><i class="bi bi-chevron right"></i></button>
        </div>
        <div class="period">
          <b-form-datepicker v-model="periodStart" class="inner_picker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
          <span class="dash">~</span>
          <b-form-datepicker v-model="periodEnd" class="inner_picker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
        </div>
      </div>
      <div class="mt-2">
        <b-nav tabs>
          <b-nav-item :active="false">탭 1번</b-nav-item>
          <b-nav-item :active="true">탭 2번</b-nav-item>
          <b-nav-item :active="false">탭 3번</b-nav-item>
        </b-nav>
      </div>
    </div>
    <!-- 간단한 테이블 -->
    <div class="card">
      <b-table :fields="customFields" :items="sampleDatas" responsive sticky-header :class="{ nodata: displayClass === 'nodata', load_ing: displayClass === 'loading' }"></b-table>
    </div>

    <!-- 셀 병합 필요한 경우 등 복잡한 화면의 경우 b-table-simple 사용 -->
    <div class="card">
      <b-table-simple responsive sticky-header :class="{ nodata: displayClass === 'nodata', load_ing: displayClass === 'loading' }" class="text-center">
        <colgroup>
          <col width="3rem" />
        </colgroup>
        <b-thead>
          <b-tr class="bor_btm_grey">
            <!-- no-border-right no-border-bottom  // 표 줄 없앨때 쓰는 클래스 -->
            <!-- b-table-sticky-column : 틀고정 원하는 컬럼에 class 추가 -->
            <th class="no-border-right no-border-bottom b-table-sticky-column"></th>
            <th class="no-border-right no-border-bottom b-table-sticky-column"></th>
            <th colspan="6">2023-12-11</th>
            <th colspan="6">2023-12-12</th>
            <th colspan="6">2023-12-13</th>
          </b-tr>
          <b-tr class="bor_btm_grey">
            <th class="no-border-top b-table-sticky-column"></th>
            <th class="no-border-top b-table-sticky-column"></th>
            <th colspan="3">영업(9시)</th>
            <th colspan="3">비영업(18시)</th>
            <th colspan="3">영업(9시)</th>
            <th colspan="3">비영업(18시)</th>
            <th colspan="3">영업(9시)</th>
            <th colspan="3">비영업(18시)</th>
          </b-tr>
          <b-tr class="bor_btm_grey">
            <th class="mw-3 no-border-top b-table-sticky-column text-center">분류</th>
            <th class="mw-9 no-border-top b-table-sticky-column">키워드</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
            <th>노출<br />순위</th>
            <th>최대<br />입찰가</th>
            <th>입찰가</th>
          </b-tr>
        </b-thead>
        <b-tbody role="rowgroup">
          <b-tr>
            <b-td :sticky-column="true" class="text-center">분류</b-td>
            <b-td :sticky-column="true">날짜</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
          </b-tr>
          <b-tr>
            <b-td :sticky-column="true" class="text-center">분류</b-td>
            <b-td :sticky-column="true">날짜</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>1</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
            <b-td>3</b-td>
            <b-td>{{ formatCurrency(100000) }}</b-td>
            <b-td>{{ formatCurrency(80000) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="pb-1">
      <b-button variant="outline-success" size="sm">엑셀 다운로드</b-button>
      <b-button variant="link">토글버튼&nbsp;<feather-icon icon="ChevronUpIcon" /></b-button>
      <b-button variant="link">토글버튼&nbsp;<feather-icon icon="ChevronDownIcon" /></b-button>
      <!-- 링크 타입 버튼 block 처리 시 아래 사용 -->
      <!-- <b-button block variant="link">토글버튼&nbsp;<feather-icon icon="ChevronDownIcon" /></b-button> -->
    </div>
    <div class="card">
      <div style="display: flex; gap: 0.5rem; padding: 1rem">
        <span class="append-arrow decrese text-blue">감소(아이콘포함)</span>
        <span class="append-arrow increse text-red">증가(아이콘포함)</span>
        <span class="text-blue">감소</span>
        <span class="text-red">증가</span>
        <span class="text-black">검정폰트</span>
        <span class="text-primary">primary </span>
        <span class="text-decoration-underline">밑줄</span>
        <span class="font-weight-bold">강조</span>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { getToday, addDay } from '@/utils/DateUtils'
import { formatCurrency } from '@/utils/StringUtils'
export default {
  components: {},
  mixins: [],
  data() {
    return {
      searchForms: {
        Components: []
      },
      sampleDatas: [
        {
          type: '구분1',
          keyword: '키워드1 키워드223455 키워드1 키워드22',
          rank: 1
        },
        {
          type: '구분1',
          keyword: '키워드2',
          rank: 3
        },
        {
          type: '구분2',
          keyword: '키워드3',
          rank: 2
        }
      ],
      date: this.getToday(),
      periodStart: this.addDay(this.getToday(), -6, '-'),
      periodEnd: this.getToday(),
      select: '셀렉트2',
      radio: '라디오3'
    }
  },
  computed: {
    customFields() {
      return [
        { key: 'type', label: '구분', class: 'mw-3 word-keep-all', thClass: 'text-center' },
        { key: 'keyword', label: '키워드', class: 'mw-4 ', thClass: 'text-center' },
        { key: 'rank', label: '평균 순위', class: 'mw-5 word-keep-all', thClass: 'text-center' }
      ]
    },
    displayClass() {
      if (this.loading && this.sampleDatas === null) {
        return 'loading'
      } else if (!this.loading && this.customFields && this.sampleDatas && this.sampleDatas.length === 0) {
        return 'nodata'
      } else {
        return ''
      }
    },
    ...mapGetters('common', ['loading']),
    ...mapGetters('filterParam', ['params'])
  },
  created() {},
  mounted() {},
  methods: {
    addDay,
    getToday,
    formatCurrency
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/search-form.scss';
</style>
